<template>
  <div class="full_bg bg_darkest">
    <div class="row m-0 h-100">
      <!-- SIDEBAR DESKTOP ONLY-->
      <div
        class="col-3 m-0 p-0 h-100"
        v-show="!isMobile && !isTablet"
      >
        <sidebar></sidebar>
      </div>
      <!-- BEGINNING DESKTOP AND TABLET -->
      <div
        class="col bg_darkest p-5 h-100 main"
        v-if="!isMobile"
      >
        <div class="row m-5 p-5">
          <div class="col-12 p-0 mb-md-4 mb-1 pb-4 font-playfair color_sand">
            <i><span style="font-weight: 900">Welcome to MORE Family Concierge</span> - your very own private Concierge service.<br>
              At this exciting beginning of your journey, a personal travel planner is at your disposal
              to ensure that every aspect of your trip is taken care of.</i>
          </div>
          <div class="col-12 p-0 m-0 mb-md-4 mb-1 pb-4 font3 color_sand">
            <div class="row m-0 p-0">
              <div class="col-6 p-0 m-0 pr-3 font3 color_sand clickable text-right">
                <span
                  @click="registration = false"
                  :class="{'register_login' : !registration}"
                >Login</span>
              </div>
              <div class="col-6 p-0 m-0 pl-3 font3 color_sand clickable text-left">
                <span
                  @click="registration = true"
                  :class="{'register_login' : registration}"
                >Register</span>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <form-input
              label="Email"
              inputId="username"
              type="text"
              v-model.trim="details.username"
              @keyup="checkUsername"
            />
          </div>
          <div
            class="col-12 p-1 mb-3 font5 alert-danger"
            v-if="usernameRegistrationError"
          >
            {{usernameRegistrationError}}
          </div>
          <div class="col-12 p-0">
            <form-input
              :label="registration ? passwordRegistrationLabel : passwordLabel"
              inputId="password"
              type="password"
              v-model.trim="details.password"
              @keyup="checkPassword"
            />
          </div>
          <div
            class="col-12 p-1 mb-3 font5 alert-danger"
            v-if="passwordRegistrationError"
          >
            {{passwordRegistrationError}}
          </div>
          <div class="col-12 p-0 mb-md-3 mt-md-3 mt-1 mb-1 font-playfair color_sand">
            <i>Your information will only be used for the purpose of enhancing your guest experience</i>
          </div>
          <div
            class="col-12 p-1 mt-3 alert-danger"
            v-if="err"
          >
            {{err}}
          </div>
          <div
            class="col-12 p-0 mt-3 alert-success"
            v-if="success"
          >
            {{success}}
          </div>
          <div class="col-12 p-0 mt-3">
            <button
              class="btn button-light-b"
              @click="registration ? registerUser() : logIn()"
              :disabled="(registration && !canRegister) || (!registration && !canLogin)"
            >
              <span v-if="registration">REGISTER</span>
              <span v-else>LOGIN</span>
            </button>
          </div>
          <div class="col-12 p-0 mt-5 font3 color_sand">
            <div class="row m-0 p-0">
              <div class="col-12 p-0 m-0 font3 color_sand clickable">
                <span @click="showModalForgotPassword = true">Forgot password?</span>
              </div>
            </div>
          </div>
          <forgot-password
            v-if="showModalForgotPassword"
            :usrname="details.username"
            @close="hideForgotPassword"
          ></forgot-password>
          <reset-password
            v-if="showModalResetPassword"
            :code="code"
            :forced="user ? user.resetPassword : false"
            @close="hideResetPassword"
          ></reset-password>
        </div>
      </div>
      <!-- END DESKTOP AND TABLET -->
      <!-- BEGINNING MOBILE ONLY -->
      <div
        class="col bg_darkest p-4"
        :style="{height: fullWindowHeight}"
        v-else
      >
        <div class="row m-0 p-0">
          <div class="col-12 m-0 pl-0 py-3 pr-3 mb-3 text-right">
            <img
              src="../assets/images/more_logo.png"
              style="max-width: 40vw;"
            >
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 p-0 mb-1 pb-3 font-playfair color_sand">
            <i>
              <span style="font-weight: 900; font-size: 24px;">Welcome to MORE Family Concierge</span>
            </i>
          </div>

          <div class="col-12 p-0 m-0 mb-md-4 mb-1 pb-4 font3 color_sand">
            <div class="row m-0 p-0">
              <div class="col-6 p-0 m-0 pr-3 font5 color_sand clickable text-right">
                <span
                  @click="registration = false"
                  :class="{'register_login' : !registration}"
                >Login</span>
              </div>
              <div class="col-6 p-0 m-0 pl-3 font5 color_sand clickable text-left">
                <span
                  @click="registration = true"
                  :class="{'register_login' : registration}"
                >Register</span>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <form-input
              label="Email"
              :mobile=true
              inputId="username"
              type="text"
              v-model.trim="details.username"
              @keyup="checkUsername"
            />
          </div>
          <div
            class="col-12 p-1 mb-3 font6 alert-danger"
            v-if="usernameRegistrationError"
          >
            {{usernameRegistrationError}}
          </div>
          <div class="col-12 p-0">
            <form-input
              :label="registration ? passwordRegistrationLabel : passwordLabel"
              :mobile=true
              inputId="password"
              type="password"
              v-model.trim="details.password"
              @keyup="checkPassword"
            />
          </div>
          <div
            class="col-12 p-1 mb-3 font6 alert-danger"
            v-if="passwordRegistrationError"
          >
            {{passwordRegistrationError}}
          </div>
          <div class="col-12 p-0 mb-md-3 mt-md-3 mt-1 mb-1 font-playfair font5 color_sand">
            <i>Your information will only be used for the purpose of enhancing your guest experience</i>
          </div>
          <div
            class="col-12 p-1 mt-3 font6 alert-danger"
            v-if="err"
          >
            {{err}}
          </div>
          <div
            class="col-12 p-0 mt-3 font6 error"
            v-if="success"
          >
            {{success}}
          </div>
          <div class="col-12 p-0 mt-3">
            <button
              class="btn button-light-b"
              @click="registration ? registerUser() : logIn()"
              :disabled="(registration && !canRegister) || (!registration && !canLogin)"
            >
              <span v-if="registration">REGISTER</span>
              <span v-else>LOGIN</span>
            </button>
          </div>
          <div class="col-12 p-0 mt-5 font5 color_sand clickable">
            <span @click="showModalForgotPassword = true">Forgot password?</span>
          </div>
          <forgot-password
            v-if="showModalForgotPassword"
            :usrname="details.username"
            @close="hideForgotPassword"
          ></forgot-password>
          <reset-password
            v-if="showModalResetPassword"
            :code="code"
            :forced="user.resetPassword"
            @close="hideResetPassword"
          ></reset-password>
        </div>
      </div>
      <!-- END MOBILE ONLY -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
// import { profileCompleted } from '../helpers/users.js'
import { validateEmailAddress } from '../helpers/users.js'
const keyUpTimeout = 900
export default {
  name: 'Login',
  components: {
    sidebar: () => import('@/components/SideBar202012.vue'),
    'form-input': () => import('@/components/FormInput.vue'),
    'forgot-password': () => import('@/components/modals/ForgotPassword.vue'),
    'reset-password': () => import('@/components/modals/ResetPassword.vue')
  },
  data () {
    return {
      details: {
        username: '',
        password: ''
      },
      code: null,
      email: null,
      err: '',
      fullWindowHeight: null,
      checkingRegistrationFields: false,
      usernameRegistrationError: '',
      passwordRegistrationError: '',
      passwordLabel: 'Password',
      passwordRegistrationLabel: 'New Password',
      showModalForgotPassword: false,
      showModalResetPassword: false,
      registration: false,
      keyUpHandler: null,
      success: null
    }
  },
  computed: {
    ...mapGetters([
      'appVersionNum',
      'isMobile',
      'isTablet',
      'isTest',
      'token',
      'profile',
      'user',
      'windowHeight'
    ]),
    canLogin () {
      return this.details.username && this.details.password
    },
    canRegister () {
      return (
        this.details.username &&
        this.details.password &&
        !this.checkingRegistrationFields &&
        !this.usernameRegistrationError &&
        !this.passwordRegistrationError
      )
    }
  },
  watch: {
    registration: {
      handler (newVal) {
        this.clearMessages()
      },
      immediate: true
    }
  },
  async created () {
    if (this.$route.params) {
      this.email = this.$route.params.email
      this.code = this.$route.params.code
      if (this.email) {
        try {
          const res = await this.validateEmail({
            email: this.email,
            code: this.code
          })
          this.success = res.message
        } catch (error) {
          console.error('error', error)
          this.err = error
        }
      } else {
        if (this.code) {
          this.showModalResetPassword = true
        }
      }
    }
    if (this.user) {
      try {
        await this.logout()
      } catch (error) {
        console.error('Login', error)
      }
    }
    this.fullWindowHeight = this.windowHeight + 'px'
  },
  methods: {
    ...mapActions([
      'getProfile',
      'login',
      'logout',
      'register',
      'validPassword',
      'validateEmail'
    ]),
    checkPassword () {
      this.success = null
      this.passwordRegistrationError = null
      this.checkingRegistrationFields = true
      if (this.keyUpHandler) {
        clearTimeout(this.keyUpHandler)
      }
      if (this.details.password) {
        this.keyUpHandler = setTimeout(async () => {
          if (this.registration) {
            try {
              const res = await this.validPassword(this.details.password)
              if (!res.success) {
                this.passwordRegistrationError = res.message
              }
            } catch (error) {
              this.passwordRegistrationError = error
            }
          }
          this.checkingRegistrationFields = false
        }, keyUpTimeout)
      }
    },
    checkUsername () {
      this.success = null
      this.usernameRegistrationError = null
      this.checkingRegistrationFields = true
      if (this.keyUpHandler) {
        clearTimeout(this.keyUpHandler)
      }
      this.keyUpHandler = setTimeout(async () => {
        if (this.registration) {
          if (!validateEmailAddress(this.details.username)) {
            this.usernameRegistrationError = 'This is not a valid email address'
          }
        }
        this.checkingRegistrationFields = false
      }, keyUpTimeout)
    },
    clearMessages () {
      this.err = null
      this.checkingRegistrationFields = false
      this.usernameRegistrationError = ''
      this.passwordRegistrationError = ''
      if (this.registration) {
        this.success = null
      }
      this.details.username = ''
      this.details.password = ''
    },
    hideForgotPassword () {
      this.showModalForgotPassword = false
    },
    hideResetPassword () {
      this.details.password = ''
      this.passwordLabel = 'Please fill in your new password'
      this.showModalResetPassword = false
    },
    async logIn (event) {
      if (event) {
        event.preventDefault()
      }
      this.err = ''
      let loggedIn
      let res
      try {
        res = await this.login(this.details)
        loggedIn = res.success
      } catch (error) {
        this.err = error || 'Wrong username or password'
      }
      if (loggedIn) {
        if (this.user.resetPassword) {
          this.code = loggedIn.code
          this.showModalResetPassword = true
        } else {
          await this.getProfile(this.user.id)
          // if (profileCompleted(this.profile)) {
          this.$router.push('/')
          // } else {
          //   this.$router.push('/profile')
          // }
        }
      } else {
        this.err = this.err
          ? this.err
          : res && res.message
            ? res.message
            : 'Wrong username or password'
      }
    },
    async registerUser (event) {
      if (event) {
        event.preventDefault()
      }
      this.err = ''
      let res
      try {
        res = await this.register(this.details)
        this.registration = false
        this.success = res.message
      } catch (error) {
        this.err = error || 'Error creating user'
      }
    }
  }
}
</script>
<style scoped>
.main {
  overflow-y: auto;
}
.register_login {
  text-decoration: underline;
}
/* .bg_darkest {
  height: 100vh;
} */
</style>
